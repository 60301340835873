import React from "react"
import PropTypes from "prop-types"
import Outbound from "./outbound"

const Dissertation = ({ title, supervisor, url }) => (
  <section style={{ marginTop: "2rem", marginBottom: "4rem" }}>
    <p><span>Dissertation</span> <span
      style={{
        fontStyle: "oblique",
        fontWeight: "bold",
        paddingLeft: "0.33rem",
        paddingRight: "0.66rem",
      }}>{title}</span>
      <span style={{ fontSize: "smaller" }}>supervisor</span> <span
        style={{ fontStyle: "oblique", fontWeight: "bold", paddingRight: "0.33rem" }}>{supervisor}</span>
      <Outbound url={url}/></p>
  </section>
)

Dissertation.propTypes = {
  title: PropTypes.string.isRequired,
  supervisor: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default Dissertation
