import React from "react"
import PropTypes from "prop-types"
import "./skills.css"
import Bullet from "./bullet"

const Skills = ({ keys }) => (
  <ul className='skills'>
    {keys.map((key, index) => <li key={index}><span className='tag'>{key}</span>{index < keys.length - 1 ?
      <Bullet/> : ""}</li>)}
  </ul>
)


Skills.propTypes = {
  keys: PropTypes.array.isRequired,
}

export default Skills
