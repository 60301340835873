import React from "react"
import PropTypes from "prop-types"

const Programme = ({ name, children, organisation }) => (
  <section>
    <h3>{name}, {organisation}</h3>
    {children}
  </section>
)


Programme.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  organisation: PropTypes.string.isRequired,
}

export default Programme
