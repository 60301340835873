import React from "react"
import PropTypes from "prop-types"

const TechnicalReview = ({ name, publisher, children }) => (
  <section className='technicalReview'>
    <h4 style={{ color: "dimgrey" }}>{name}
      <small style={{ color: "darkgrey", paddingLeft: "1em" }}>{publisher}</small>
    </h4>
    {children}
  </section>
)

TechnicalReview.propTypes = {
  name: PropTypes.string.isRequired,
  publisher: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default TechnicalReview
