import React from "react"
import PropTypes from "prop-types"
import Postnominal from "./postnominal"

const Title = ({ name, postnominals }) => (
  <header style={{ textAlign: "center", color: "dimgray", marginTop: "5rem", marginBottom: "5rem" }}>
    <h1 style={{ marginBottom: 0 }}>{name}</h1>
    <p style={{ marginBottom: "0rem" }}>
      {postnominals.map(a =>
        <Postnominal
          key={a.abbr}
          abbr={a.abbr}
          full={a.full}
          institution={a.institution}
          id={a.id}
        />)}</p>
  </header>
)


Title.propTypes = {
  name: PropTypes.string.isRequired,
  postnominals: PropTypes.array.isRequired,
}

export default Title
