import React from "react"
import PropTypes from "prop-types"
import voca from "voca"

const Area = ({ title, children }) => (
  <section id={voca.kebabCase(title)}>
    <hr/>
    <h1 style={{ textAlign: "center", marginTop: "5rem", color: "dimgrey" }}>{title}</h1>
    {children}
  </section>
)


Area.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default Area
