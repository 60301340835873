import React from "react"
import { ApacheCommons, ApacheMaven } from "./technology"
import TechnicalReview from "./technical_review"

const TechnicalReviews = () => (
  <section>
    <h3>Technical Reviews</h3>
    <TechnicalReview
      publisher='Packt Publishing'
      name='Getting Started With Apache Maven'>
      <p>
        Acted as Technical Reviewer on an
        introductory-level video course about <ApacheMaven/>. An interesting
        experience, giving me some insights into the creation of video training
        courses.
      </p>
      <p>
        The first time I've acted as a reviewer for a video course.
      </p>
    </TechnicalReview>
    <TechnicalReview
      publisher='Manning Publications Co.'
      name='Jakarta Commons Online Bookshelf'>
      <p>
        Over the years, I've contributed a quantity of documentation for open source
        projects including <ApacheCommons/>. It's one of the best ways to learn a technology
        but this was the first
        time I've been involved in something more substantial and systematic.
      </p><p>
      Left me with a deep respect for the effort and energy required to produce a book.
    </p>
    </TechnicalReview>
  </section>
)

export default TechnicalReviews
