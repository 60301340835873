import React from "react"
import PropTypes from "prop-types"
import Outbound from "./outbound"

const Uni = ({ name, children, url }) => (
  <section style={{ textAlign: "center", marginTop: "5rem" }}>
    <h2>{name} <Outbound url={url}/></h2>
    {children}
  </section>
)

Uni.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default Uni
