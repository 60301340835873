import React from "react"
import PropTypes from "prop-types"

const Supervisor = ({ level, subject }) => (
  <h3 style={{ color: "darkgrey" }}>{level} Supervisor, {subject} </h3>
)

Supervisor.propTypes = {
  level: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
}

export default Supervisor
