import React from "react"
import PropTypes from "prop-types"

const Highlights = ({ lines }) => (
  <ul style={{ marginLeft: "3rem" }}>
    {lines.map(line => <li key={line}>{line}</li>)}
  </ul>
)


Highlights.propTypes = {
  lines: PropTypes.array.isRequired,
}

export default Highlights
