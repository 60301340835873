import React from "react"
import Bullet from "./bullet"
import "./nav.css"
import voca from "voca"

const Nav = React.memo(({ keys }) => (
  <nav className='inPage'>
    <ul>
      {keys.map((key, index) =>
        <li key={key}>
          <a href={`#${voca.kebabCase(key)}`}>
            <span className='tag'>{key}</span>
          </a>
          {index < keys.length - 1 ? <Bullet/> : ""}
        </li>)}
    </ul>
  </nav>
))

export default Nav
