import React from "react"
import Area from "./area"
import Position from "./position"
import Programme from "./programme"
import Project from "./project"
import Highlights from "./highlights"
import Outline from "./outline"
import Skills from "./skills"
import { mbcs } from "../data/professional"

const Professional = () => (
  <Area title='Professional' id='professional'>

    <h3>Professional Bodies</h3>
    <h4 id={mbcs.id}>{mbcs.institution}</h4>
    <ul>
      <li>{mbcs.full}</li>
    </ul>
    <Position
      title='Director and Principal Engineer'
      organisation='Tech Up North Ltd'
      outline='In 2016 I co-founded a small software engineering business undertaking external and internal projects.
        Contracts successfully delivered follow.'
    >
      <Programme
        name='US Sportsbook Programme'
        organisation='William Hill PLC'
      >
        <p>
          In 2018 a landmark ruling by the US Supreme Court opened up to all 50 States rights to license Sports
          bookmaking.
          A rush to market followed. William Hill PLC decided to develop a mix of existing technologies into a website
          aimed
          at the new online market in New Jersey. This was followed up by a second generation website based on new
          technologies with a better strategic fit. Tech Up North Ltd developed a series of microservices ― both solo
          and
          within Agile teams ― mediating the flow of data from trader to browser.
        </p>
        <Project when='Spring and Summer 2018' name='Lean First Generation Microservices'>
          <Highlights lines={[
            "Developed dockerized Spring Boot microservices on Linux in AWS",
            "Small multidisciplinary collaborative team",
            "Close collaboration with test automation specialists achieved high coverage",
            "Close collaboration with key stakeholders maintained good business alignment",
            "Close collaboration with overseas teams ensured end-to-end high quality data flow",
            "Supported drive through US Certification to Go Live",
            "Custom Splunk app provided insights into data flow",
            "Lean and Agile approach practicing Scrum and then Kanban"]}/>
          <Skills keys={[
            "Java 8", "Akka", "Akka Streams", "Scala", "Jackson", "MacOSX", "TDD", "BDD", "Mockito", "Maven", "Git",
          ]}/>

        </Project>
        <Project name='Second Generation Proof-of-Concept Microservices' when='Autumn 2018'>
          <Highlights lines={[
            "Proof-of-concept aimed at key senior management stakeholders",
            "Successfully demonstrated that a new engine could cost-effectively supply data",
            "Solo analyse, design and develop adaption layer",
            "Spring Boot microservices in Docker on AWS",
            "Lean approach tracked using Kanban"]}/>
          <Skills keys={[
            "Java 8", "Akka", "Akka Streams", "Jackson", "MacOSX", "Maven", "Git",
          ]}/>
        </Project>
        <Project name='Second Generation Microservices' when='Winter 2019'>
          <Highlights lines={[
            "Horizontally scalable stateless architecture",
            "Spring Boot microservices deployed in Kubernetes on AWS",
            "Backing new high volume website coded in React.js",
            "Small multidisciplinary team",
            "Agile practicing Scrum",
            "Test First, TDD, BDD and end-to-end automation"]}/>
          <Skills keys={[
            "Java 8", "Spring Boot", "Spring Kafka", "Spring", "Spring WebFlux", "Reactive Streams", "Jackson", "MacOSX",
            "Maven", "node.js", "Mockito", "AssertJ", "Git", "CI/CD pipelines",
          ]}/>
        </Project>
      </Programme>
      <Programme
        organisation='William Hill PLC'
        name='UK Retail Programme'>
        <p>
          The William Hill retail estate had around 3500 locations in the UK. The programme aim was to replace all
          third
          party kiosks with more advanced products owned by William Hill. The customer-facing Kiosk and the
          staff-facing
          Tablet were HTML5 Chrome apps running React.js and node.js backed by Java microservices both locally and
          in a
          private cloud.
        </p>
        <p>
          This was a major programme running over many years with Scrum-at-scale organised into many teams. Tech Up
          North
          Ltd contributed to the project linking online and kiosk accounts.
        </p>
        <Project name='Kiosk and Tablet Plus Project' when='2017'>
          <Highlights lines={[
            "Dockerized microservices deployed to private cloud by CI/CD pipelines",
            "Full scale Scrum teams in a variety of locations in various nations",
            "Close collaboration with test-automation experts ensured frequent releases",
            "Developed Splunk apps helping support to comprehend data flow in the estate"]}/>
          <Skills keys={[
            "Java 8", "Spring Boot", "Spring", "Jackson", "JAX-RS", "React.js", "HTML5", "CI/CD pipelines", "TDD", "BDD",
          ]}/>
        </Project>
      </Programme>
      <Programme organisation='Trustonic' name='Mobile Phone Security'>
        <p>
          Trustonic is a Cambridge start-up securing mobile phones focused on ARM-based hardware cryptography.
          Customers
          include many high profile, global blue chip corporations. Tech Up North Ltd was brought in to design,
          develop
          and
          test a key command line tool to a fixed contractual deadline.
        </p>
        <Project name='Cryptography Keystore Tooling' when='Autumn 2016'>
          <Highlights lines={[
            "Comprehensive automated end-to-end test framework delivered",
            "Demonstrated compliance with external and internal API standards"]}/>
          <Skills keys={[
            "Java", "Javax Cryptography", "Bouncy Castle", "Maven", "Mockito", "TDD", "BDD", "Test First",
          ]}/>
        </Project>
      </Programme>
    </Position>
    Before Tech Up North Ltd
    <Position organisation='William Hill PLC' title='Java Developer' duration='3 years'>
      <Project name='Liability Project'>
        <Highlights lines={[
          "Near-real-time (<15 seconds) data analytics from around 10k points-of-sale",
          "Large data volumes (256G peak memory usage per instance) with non-linear shape",
          "High throughput (250 incoming messages per second peak per HTTP endpoint)",
          "Agile delivery using full-size multidisciplinary Scrum team",
          "Supported production by diagnosing faults and tuning peak performance using JMX",
          "Mentored a graduate",
          "Routinely delivered over 90% code coverage",
          "Developed correctness-at-volume automated test tool for like-live environment"]}/>
        <Skills keys={[
          "Java", "Spring", "Spring Integration", "ActiveMQ", "Tomcat", "MySQL", "Hibernate", "WebSockets", "Eclipse RCP",
          "Web Services", "JAX-RS", "Apache CXF", "TDD", "BDD", "Test First", "Mockito", "CI", "Pair Programming", "JMS",
        ]}/>
      </Project>
      <Project name='Kiosk Project'>
        <Outline>
          Contributed to the front end team developing a novel retail Kiosk solution
        </Outline>
        <Skills keys={[
          "JavaScript", "react.js", "angular.js", "HTML5", "CSS3", "node.js", "npm", "Chrome app", "puppet",
        ]}/>
      </Project>
    </Position>
    <Position organisation='Hedtek Ltd' title='Lead Agilist' duration='9 months'>
      <Skills keys={[
        "Ruby on Rails", "Coffeescript", "HTML", "angular.js", "BDD", "TDD", "Git", "Chef", "PostgreSQL",
      ]}/>
    </Position>
    <Position
      organisation='Retail Express'
      title='Senior Consultant'
      duration='5 Years'
      outline='Brought into this retail data analysis startup at around 6 months in.'
    >
      <Skills keys={[
        "Java", "J2EE", "Pair Programming", "led some small teams", "mentored graduates", "map reduce with GridGain",
        "Design Patterns", "Offshore collaboration", "Agile UML", "Red Hat Linux", "Apache Tomcat and Jetty",
        "Webservices", "HTML", "Swing", "Subversion", "Weblogic", "Websphere", "Oracle", "DB2", "MySQL",
      ]}/>
    </Position>
    <Position
      organisation='Du Maurier Limited'
      title='System Development Engineer'
      duration='2 years'
      outline='Innovative HTTP-based internet enabled point-of-sale solution. Remote working.'
    >
      <Skills keys={[
        "Java", "J2EE", "HTML", "MySQL", "Ant Build", "Scripted Deployed onto Mandrake Linux and OpenLDAP",
      ]}/>
    </Position>
    <Position
      organisation='UniPro'
      title='Trainee • Software Engineer • Consultant'
      duration='4 years'
      outline='Human resources software for a small software house with clients in The Gulf and in Africa. ISO-9000 accredited.'>
      <Skills keys={[
        "DSDM", "Visual Basic", "Oracle", "PL/SQL.", "mentoring", "interviewing", "led small teams",
      ]}/>
    </Position>
  </Area>
)

export default Professional
