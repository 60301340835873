import React from "react"
import PropType from "prop-types"
import "./postnominal.css"
import "react-tippy/dist/tippy.css"
import { Tooltip } from "react-tippy"

class Postnominal extends React.PureComponent {

  render() {
    let { abbr, full, institution, id } = this.props
    let tip = <div>{full}<br/><small>{institution}</small></div>
    return (
      <Tooltip
        html={tip}
        position="bottom"
        trigger="mouseenter"
        size="small"
        delay="50"
      >
        <span className='postNominal'>
          <a style={{ textDecoration: "none" }} href={`#${id}`}>{abbr}</a>
        </span>
      </Tooltip>
    )
  }
}

Postnominal.propTypes = {
  abbr: PropType.string.isRequired,
  full: PropType.string.isRequired,
  id: PropType.string.isRequired,
  institution: PropType.string,
}

export default Postnominal