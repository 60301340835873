import React from "react"
import Area from "./area"
import Uni from "./uni"
import Degree from "./degree"
import Course from "./course"
import Dissertation from "./dissertation"
import Demonstrator from "./demonstrator"
import Supervisor from "./supervisor"
import { acs, bscMaths, manchester, mscMaths, warwick } from "../data/education/unis"

const Academia = () => (
  <Area title='Academia'>
    <Uni name={warwick.name} url={warwick.url}>
      <Degree
        level={mscMaths.level}
        subject={mscMaths.subject}
        award={mscMaths.award}
        url={mscMaths.url}
        id={mscMaths.id}
      >
        <Course subject='Profinite Group Theory' score='92% (4 alphas)'/>
        <Course subject='The Construction of Mathematical Knowledge' score='73%'/>
        <Course subject='Geometric Group Theory' score='73% (1 alpha)'/>
        <Course subject='Advanced Mathematical Thinking' score='72%'/>

        <Dissertation
          title='Study on the Thinking of Mathematicians'
          supervisor='David Tall'
          url='http://homepages.warwick.ac.uk/staff/David.Tall/'/>
      </Degree>
      <Degree
        level={bscMaths.level}
        subject={bscMaths.subject}
        award={bscMaths.award}
        url={bscMaths.url}
        id={bscMaths.id}
      />
      <Supervisor level='Undergraduate' subject='Mathematics'/>
    </Uni>
    <Uni name={manchester.name} url={manchester.url}>
      <Degree
        level={acs.level}
        subject={acs.subject}
        award={acs.award}
        url={acs.url}
        id={acs.id}>
        <Course subject='Machine Learning' score='94%'/>
        <Course subject='Automated Reasoning' score='88%'/>
        <Course subject='Knowledge, Representation and Reasoning' score='88%'/>
        <Course subject='Future Multi-Core Computing' score='85%'/>
      </Degree>
      <Demonstrator level='Masters' subject='Agile Software Development'/>
    </Uni>
  </Area>

)

export default Academia
