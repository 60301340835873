import React from "react"
import PropTypes from "prop-types"

export const ApacheCommons = () =>
  <Technology name='Apache Commons' url='https://commons.apache.org'/>

export const ApacheMaven = () =>
  <Technology name='Apache Maven' url='https://maven.apache.org'/>

const Technology = ({ name, url }) =>
  <a href={url} target="_blank" rel="noopener noreferrer tag">
    {name}
  </a>

Technology.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default Technology
