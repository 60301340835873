import React from "react"
import Area from "./area"
import TechnicalReviews from "./technical_reviews"


//                   Should this be contributions...?
const Vocational = () => (
  <Area title='Vocational' id='vocational'>
    <TechnicalReviews/>
  </Area>
)

export default Vocational

