import React from "react"

const warwick = {
  name: "University of Warwick",
  url: "https://www.warwick.ac.uk",
}

const bscMaths = {
  abbr: "BSc",
  full: "Bachelor of Science, Mathematics with Honours 1st Class",
  institution: warwick.name,
  id: "uni-war-bsc",
  subject: "Mathematics",
  award: "Honours 1st Class",
  level: "Bachelor of Science",
  url: "https://warwick.ac.uk/fac/sci/maths/",
}
const mscMaths = {
  abbr: "MSc",
  full: "Master of Science, Interdisciplinary Mathematics with Distinction",
  institution: warwick.name,
  id: "uni-war-bsc",
  level: "Master of Science",
  subject: "Interdisciplinary Mathematics",
  award: "Distinction",
  url: "https://warwick.ac.uk/fac/sci/maths/",
}

const manchester = {
  name: "University of Manchester",
  url: "https://www.manchester.ac.uk",
}

const acs = {
  abbr: "PgDip",
  level: "Postgraduate Diploma",
  subject: "Advanced Computer Science",
  award: "Distinction",
  full: "Postgraduate Diploma, Advanced Computer Science with Distinction",
  institution: manchester.name,
  id: "uni-man-acs",
  url: "https://www.cs.manchester.ac.uk/",
}

export { bscMaths, mscMaths, acs, warwick, manchester }