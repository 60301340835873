import React from "react"
import PropTypes from "prop-types"
import Outbound from "./outbound"

const Degree = ({ id, level, subject, award, children, url }) => (
  <section id={id} style={{ marginTop: "3rem", marginBottom: "3rem" }}>
    <h3 style={{ color: "dimgrey" }}>{level}, {subject} with {award} <Outbound url={url}/></h3>
    {children}
  </section>
)

Degree.propTypes = {
  children: PropTypes.node,
  level: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  award: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default Degree
