import React from "react"
import PropTypes from "prop-types"

const Course = ({ subject, score }) => (
  <section>
    <p><span style={{ fontWeight: "bold" }}>{subject}</span> <span style={{ color: "dimgrey" }}>{score}</span></p>
  </section>
)

Course.propTypes = {
  subject: PropTypes.string.isRequired,
  score: PropTypes.string.isRequired,
}

export default Course
