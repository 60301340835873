import React from "react"
import Title from "./title"
import Academia from "./academia"
import Professional from "./professional"
import Vocational from "./vocational"
import Nav from "./nav"
import { acs, bscMaths, mscMaths } from "../data/education/unis"
import { mbcs } from "../data/professional"

const Resume = React.memo(() => (
  <article>
    <Nav keys={["professional", "academia", "vocational"]}/>
    <Title name='Robert Burrell Donkin' postnominals={[bscMaths, acs, mscMaths, mbcs]}/>
    <Professional/>
    <Academia/>
    <Vocational/>
  </article>

))

export default Resume
