import React from "react"
import PropTypes from "prop-types"

const Project = ({ name, children, when }) => (
  <section>
    <h4 style={{ marginBottom: "0.25rem", marginTop: "2rem" }}>{when ? `${when} \u2015 ` : ""}{name}</h4>
    {children}
  </section>
)


Project.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  when: PropTypes.string,
}

export default Project
