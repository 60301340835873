import React from "react"
import PropTypes from "prop-types"
import Outline from "./outline"

const Position = ({ title, children, organisation, duration, outline }) => (
  <section>
    <h2 style={{
      marginTop: "5rem",
      marginBottom: "0.5rem",
    }}>{title}, {organisation} {duration ? ` • ${duration}` : ""}</h2>
    {outline ? <Outline><p>{outline}</p></Outline> : ""}
    {children}
  </section>
)


Position.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  organisation: PropTypes.string.isRequired,
  duration: PropTypes.string,
  outline: PropTypes.string,
}

export default Position
